import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ContrastContext from '../context/contrastContext';
import Section from '../components/Section';

function DownloadButton({ src, text, className }) {
  const { isHighContrastEnabled } = useContext(ContrastContext);

  let colorClasses = ' bg-red hover:bg-redHover focus:bg-redPressed';
  if (isHighContrastEnabled) {
    colorClasses = ' bg-redHC hover:bg-redHoverHC focus:bg-redPressedHC';
  }

  className = `${
    className == null ? '' : className
  } button block text-center md:inline-block mb-12 px-12 py-3 rounded-full text-white cursor-pointer border border-transparent`;

  className += colorClasses;

  function sendDownloadEvent() {
    if (typeof window.plausible === 'function') {
      window.plausible('Report Download', {
        callback: () => console.info('Sent Report Download event'),
      });
    } else {
      console.warn('Ignoring Report Download Event: localhost');
    }
  }

  return (
    <a
      className={className}
      role="button"
      tabIndex={0}
      onKeyPress={sendDownloadEvent}
      onClick={sendDownloadEvent}
      href={src}
      download
    >
      {text}
    </a>
  );
}

DownloadButton.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

DownloadButton.defaultProps = {
  text: 'Download the report',
};

function ReportDownloadSection({ downloads }) {
  function formatDownloadSrc(path) {
    return path.replace(/^(\/static)/, '');
  }

  return (
    <Section>
      <div className="container md:flex gap-12 pt-12">
        {downloads.map((download) => (
          <DownloadButton
            src={formatDownloadSrc(download.path)}
            text={download.label}
          />
        ))}
      </div>
    </Section>
  );
}

export default ReportDownloadSection;
