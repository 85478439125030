import React from 'react';
import { useContext } from 'react';

import LinkButton from '../components/LinkButton';
import ContrastContext from '../context/contrastContext';

const NewsletterSignupForm = () => {
  const { isHighContrastEnabled } = useContext(ContrastContext);

  const sendNewsletterSignupEvent = (e) => {
    if (typeof window.plausible === 'function') {
      window.plausible('Newsletter Signup', {
        callback: () => console.info('Sent Newsletter Signup event'),
      });
    } else {
      console.warn('Ignoring Newsletter Signup Event: localhost');
    }
  };

  return ( <br></br>

  );
};

export default NewsletterSignupForm;
