import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import LocaleContext from '../context/localeContext';
import { BlogContent, MarkdownContent } from '../components/Content';
import Hero from '../components/Hero';
import Section from '../components/Section';
import { ArrowLeftIcon, ArrowRightIcon } from '../components/Icon';
import BlogRoll from '../components/BlogRoll';
import ReportDownloadSection from '../components/ReportDownloadSection';

const ResearchPublication = ({
  title,
  date,
  heroImage,
  downloads,
  takeaways,
  content,
  contentComponent,
  tags,
  relatedPages,
}) => {
  const { locale } = useContext(LocaleContext);
  const ContentComponent = contentComponent ?? BlogContent;
  const [currentSlide, setCurrentSlide] = useState(1);
  const formattedDate = DateTime.fromISO(date)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_FULL);

  const currentSlideContent = () => {
    return takeaways[currentSlide - 1];
  };

  const nextSlide = () => {
    if (currentSlide === takeaways.length) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
    return false;
  };

  const prevSlide = () => {
    if (currentSlide === 1) {
      setCurrentSlide(takeaways.length);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
    return false;
  };

  return (
    <>
      <Hero
        image={heroImage}
        content={`${formattedDate} \n## ${title}`}
        autoHeaderText={false}
      />
      {downloads && <ReportDownloadSection downloads={downloads} />}
      {takeaways && (
        <Section colorScheme="black">
          <div className="container py-8">
            <div className="row flex justify-between">
              <span className="bg-theme-underline text-lg">Key Takeaways</span>
              <span>
                {currentSlide}/{takeaways.length}
              </span>
            </div>
            <div className="row py-12 px-12">
              <MarkdownContent content={currentSlideContent()} />
            </div>
            <div className="row">
              <button
                className="group mr-4 hover:text-primary"
                onKeyPress={() => prevSlide()}
                onClick={() => prevSlide()}
              >
                <ArrowLeftIcon className="inline-block h-4 w-4 mr-2 transition-transform group-hover:-translate-x-4" />
                Prev
              </button>
              <button
                className="group ml-8 hover:text-primary"
                onKeyPress={() => nextSlide()}
                onClick={() => nextSlide()}
              >
                Next
                <ArrowRightIcon className="inline-block h-4 w-4 ml-2 transition-transform group-hover:translate-x-4" />
              </button>
            </div>
          </div>
        </Section>
      )}
      <Section colorScheme="white" overlayLength="large">
        <div className="container py-12">
          <ContentComponent content={content} />
          {tags && tags.length ? (
            <div className="mt-2">
              <h4>Tags</h4>
              <ul>
                {tags.map((tag) => (
                  <li key={tag + `tag`}>
                    {/* TODO: Link to some page showing posts with this link? */}
                    <span>{tag}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </Section>
      {relatedPages && !!relatedPages.length && (
        <Section overlaySide="left">
          <div className="container py-12">
            <div className="row flex flex-col md:flex-row pb-2 gap-x-36">
              <div className="md:w-1/3 flex flex-wrap content-between text-base">
                <h2 className="mb-6 md:mb-12">
                  <span className="bg-theme-underline">Related Content</span>
                </h2>
              </div>
            </div>
            <div className="row">
              <BlogRoll blogPosts={relatedPages} />
            </div>
          </div>
        </Section>
      )}
    </>
  );
};

ResearchPublication.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  heroImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  downloadSrc: PropTypes.string,
  takeaways: PropTypes.array,
  content: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  relatedPages: PropTypes.arrayOf(PropTypes.object),
};

export default ResearchPublication;
