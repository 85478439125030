import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { DateTime } from 'luxon';

import { ArrowRightIcon } from '../components/Icon';
import LocaleContext from '../context/localeContext';

function templateKeyToTextColorClass(templateKey) {
  switch (templateKey) {
    case 'BlogPostTemplate':
      return 'text-pink';
    case 'CountryStudyTemplate':
      return 'text-purple';
    case 'ResearchPublicationTemplate':
      return 'text-red';
    case 'PressReleaseTemplate':
      return 'text-yellow';
    case 'EventTemplate':
      return 'text-blue';
    case 'InTheNewsTemplate':
      return 'text-green';
    case 'DisinfoAdsTemplate':
      return 'text-teal';
    case 'JobPostTemplate':
      return 'text-gray-500';
    default:
      return 'text-gray-900';
  }
}

const CategoryLabel = ({ templateKey }) => (
  <>
    {{
      BlogPostTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-pink text-white">
          Blog
        </span>
      ),
      CountryStudyTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-purple text-white">
          Country Study
        </span>
      ),
      ResearchPublicationTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-red text-white">
          Research
        </span>
      ),
      PressReleaseTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-yellow text-white">
          Press Release
        </span>
      ),
      EventTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-blue text-white">
          Event
        </span>
      ),
      InTheNewsTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-green text-white">
          In The News
        </span>
      ),
      DisinfoAdsTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-teal text-white">
          Disinfo Ads
        </span>
      ),
      JobPostTemplate: (
        <span className="inline-block text-sm py-2 px-14 bg-gray-500 text-white">
          Job Posting
        </span>
      ),
    }[templateKey] || (
      <span className="inline-block text-sm py-2 px-14 bg-white">
        {templateKey}
      </span>
    )}
  </>
);

const BlogRoll = ({ blogPosts, isVerticalLayout }) => {
  const { locale } = useContext(LocaleContext);

  return (
    <div
      className={`${
        isVerticalLayout ? 'lg:px-16' : 'grid md:grid-cols-2 lg:grid-cols-3'
      } items-center justify-between py-5 gap-7`}
    >
      {blogPosts &&
        blogPosts.map(({ node: post }) => (
          <Link
            to={post.fields.slug}
            className={`group relative text-black bg-white transition drop-shadow-lg hover:drop-shadow-xl rounded-lg md:rounded-xl h-full overflow-hidden ${
              isVerticalLayout ? 'flex mb-8' : ''
            }`}
            key={post.id}
            style={{ filter: 'drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))' }} // Safari hack
          >
            <header
              className={`relative ${
                isVerticalLayout ? 'w-1/3' : 'h-56 sm:48 md:h-56 lg:h-72'
              } `}
            >
              {post.frontmatter.image ? (
                <GatsbyImage
                  image={getImage(post.frontmatter.image)}
                  alt=""
                  className="block h-full"
                />
              ) : null}
              <div
                className={`block absolute bottom-0 ${
                  isVerticalLayout ? 'right-0' : ''
                }`}
              >
                <CategoryLabel templateKey={post.frontmatter.templateKey} />
              </div>
            </header>
            <div
              className={`${
                isVerticalLayout ? 'w-2/3 p-4 md:p-8' : 'p-4 pb-6'
              }`}
            >
              <h4 className="pb-3">{post.frontmatter.title}</h4>
              <p className="pb-12">{post.frontmatter.overview}</p>
              <div
                className={`absolute ${
                  isVerticalLayout ? 'bottom-4 md:bottom-8' : 'bottom-4'
                }`}
              >
                {isVerticalLayout && (
                  <span className="inline-block font-medium mr-4">
                    {DateTime.fromISO(post.frontmatter.date)
                      .setLocale(locale)
                      .toLocaleString(DateTime.DATE_FULL)}
                  </span>
                )}
                <span className="inline-block">Find out more</span>
                <ArrowRightIcon
                  className={`${templateKeyToTextColorClass(
                    post.frontmatter.templateKey
                  )} inline-block h-4 w-4 ml-2 transition-transform group-hover:translate-x-4`}
                />
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};

BlogRoll.propTypes = {
  blogPosts: PropTypes.array.isRequired,
  isVerticalLayout: PropTypes.bool,
};

BlogRoll.defaultProps = {
  isVerticalLayout: false,
};

export default BlogRoll;

export const query = graphql`
  fragment BlogRollItem on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
      templateKey
      tags
      overview
      date
      image {
        childImageSharp {
          gatsbyImageData(
            width: 624
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`;
